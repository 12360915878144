
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.panel-channels,
[class*='panel-channels--'] {
  overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem 0;

  ::v-deep {
  }

  @include mq(m) {
    padding: $spacing * 3 $spacing * 4;
  }
}

.panel-channels__title {
  @extend %fw-semi;

  margin-bottom: $spacing * 2;
  color: $white;
  font-family: $ff-alt;
}

.panel-channels__loader {
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c-light-gray;
}

.panel-channels__loader__spiner {
  width: 20%;
  max-width: 15rem;
}

.panel-channels-inner {
  position: relative;
  z-index: 1;
}

// Transition
.channel-loaded-enter-active,
.channel-loaded-leave-active {
  transition: opacity 0.3s;
}

.channel-loaded-enter,
.channel-loaded-leave-to {
  opacity: 0;
}
